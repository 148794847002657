<template>
  <div id="root">
    <my-load-panel v-model:busy="busy"></my-load-panel>
    <div :class="cssClasses">
      <component
        :is="$route.meta.layout"
        :title="title"
        :is-x-small="screen.getScreenSizeInfo.isXSmall"
        :is-large="screen.getScreenSizeInfo.isLarge"
      >
      <router-view></router-view>
        <template #footer>
          <app-footer />
        </template>
      </component>
    </div>
  </div>
</template>

<script>
import AppFooter from "./components/app-footer";
import myLoadPanel from "./components/my-load-panel.vue";
import { useAppStore } from "./stores/app-store";
import { sizes, subscribe, unsubscribe } from "./utils/media-query";
import {
  getCurrentInstance,
  reactive,
  onMounted,
  onBeforeUnmount,
  computed
} from "vue";

function getScreenSizeInfo() {
  const screenSizes = sizes();

  return {
    isXSmall: screenSizes["screen-x-small"],
    isLarge: screenSizes["screen-large"],
    cssClasses: Object.keys(screenSizes).filter(cl => screenSizes[cl])
  };
}

export default {
  components: {
    AppFooter,
    myLoadPanel
  },
  setup() {
    const store = useAppStore();
    const vm = getCurrentInstance();
    const busy = computed({
      get: ()=>store.busy,
      set:(value)=>store.busy = value
    })
    const title = vm.proxy.$appInfo.title;
    const screen = reactive({ getScreenSizeInfo: {} });
    screen.getScreenSizeInfo = getScreenSizeInfo();

    function screenSizeChanged () {
      let info = getScreenSizeInfo();
      screen.getScreenSizeInfo = info;
      store.screenSizeInfo = info;
    }

    onMounted(() => {
      store.screenSizeInfo = getScreenSizeInfo();
      subscribe(screenSizeChanged);
      store.loadServerUrl();
    });

    onBeforeUnmount(() => {
      unsubscribe(screenSizeChanged);
    });

    const cssClasses = computed(() => {
      return ["app"].concat(screen.getScreenSizeInfo.cssClasses);
    });

    return {
      title,
      screen,
      cssClasses,
      busy
    };
  }
};
</script>

<style lang="scss">
html,
body {
  margin: 0px;
  min-height: 100%;
  height: 100%;
}

#root {
  height: 100%;
}

* {
  box-sizing: border-box;
}

.dx-loadpanel-wrapper {  
    z-index: 2000!important  
}  

.app {
  display: flex;
  height: 100%;
  width: 100%;
}
</style>
